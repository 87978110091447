@font-face {
  font-family: 'Futura';
  font-weight: 400;
  src: url(../assets/fonts/FuturaPT/FuturaCyrillicBook.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  font-weight: 700;
  // src: url(../assets/fonts/FuturaPT/FuturaCyrillicMedium.ttf);
  src: url(../assets/fonts/FuturaPT/FuturaCyrillicDemi.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'SofiaPro';
  src: url(../assets/fonts/SofiaPro/SofiaProRegularAz.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url(../assets/fonts/SofiaPro/SofiaProSemiBoldAz.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 10,
);

:root {
  --light: #ffffff;
  --dark: #141b25;
  --yellow: #efd653;
  --yellowRGB: 239, 214, 83;
  --blue: #2b57f4;
  --flat: #292c39;
  --grey: #abb0b9;

  --silver: #6d6d6d;
  --dark-yellow: #ffc300;
}

// $body-color: #141b25;
$body-color: #0f0f0f;
$light: #ffffff;
$dark: #0f0f0f;
$yellow: #efd653;
$blue: #2b57f4;
$flat: #292c39;
$grey: #6d6d6d;
$warning: rgb(255, 195, 0);

$colors: (
  'yellow': $yellow,
  'blue': $blue,
  'flat': $flat,
  'grey': $grey,
  'warning': $warning,
);

@import 'bootstrap/scss/bootstrap';

@import './ng-select.default.theme';
@import './item-creation-global';
@import './dashboard-styles';
@import './icons';

html,
body {
  // font-family: 'Futura';
  font-family: 'SofiaPro';
  min-height: 100vh;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: 'SofiaPro', sans-serif;
  color: #141b25;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-weight: 600 !important;
}

.h1,
h1 {
  font-size: 3.4rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.2rem;
}

.h5,
h5 {
  font-size: 1.1rem;
}

.h6,
h6 {
  font-size: 0.87rem;
}

p {
  font-size: 1.5rem;
  font-family: 'SofiaPro', sans-serif;
  // font-family: 'Futura', sans-serif;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-72 {
  font-size: 72px !important;
}

.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.text-primary {
  color: $yellow !important;
}

.text-secondary {
  color: $grey !important;
}

.text-muted {
  color: var(--fontMutedColour);
}

.font-courier {
  font-family: Courier, serif !important;
}

.font-courier-monospaced {
  font-family: Courier, monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #abb0b9;
}

.shadow-sm {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow {
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.16) !important;
}

.btn {
  // font-family: 'Futura';
  font-family: 'SofiaPro';
  text-transform: uppercase;
  font-weight: 600;
  transition: none;
  line-height: 1;
}

.btn:hover,
.btn:focus,
.btn {
  font-size: 18px;
  border-radius: 4px;
  padding: 12px 24px;
  text-transform: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary {
  color: $dark !important;
  border: none;
  background: $yellow !important;
}

.btn.btn-primary:active:hover,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.dropdown-item:active {
  background: rgb(219, 190, 0);
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: rgb(219, 190, 0);
  box-shadow: 0 0 0 0.2rem #efd10088;
}

.btn.btn-primary:focus,
.dropdown-item:focus {
  box-shadow: 0 0 0 0.2rem #efd10088;
}

.btn.btn-primary:disabled {
  color: gray;
}

.btn.btn-small {
  font-size: 14px;
  padding: 8px 16px;
}

.form-group {
  label {
    margin-bottom: 0;
  }
  input,
  select {
    font-family: sans-serif;
    font-size: 0.9rem;
  }
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .form-group input::-moz-placeholder {
//   font-family: sans-serif;
// }

.input-group {
  .input-group-prepend {
    .input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.form-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0;

  label {
    margin: 0;
    cursor: pointer;
  }
}

a.text-primary:hover,
a.text-primary:focus,
a.text-primary {
  color: $yellow !important;
}

.border-bottom-2 {
  border-bottom: solid 2px !important;
}

.border-primary {
  border-color: $yellow !important;
}

.border-secondary {
  border-color: $flat !important;
}

.hr-primary {
  background: -webkit-linear-gradient(right, #efd653, #a28c17);
  height: 0.1rem;
}

.image-icon {
  width: 3rem;
  height: auto;
}

.rounded {
  border-radius: 5px;
}

.dark-border {
  border: 0.5px solid #141b25;
}

.large-text {
  font-size: 3rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.logs {
  color: white;
  white-space: normal;
  overflow-y: auto;
  height: calc(100vh - 315px);
  background-color: #1f1f1f;
  font-family: monospace;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: inset 0px 0px 20px 12px black;
}

.notification-dot-holder {
  position: relative;
}

.notification-dot {
  color: rgb(0, 0, 0);
  font-size: 10px;
  position: absolute;
  bottom: -0.2rem;
  left: 0.3rem;
  border-radius: 5rem;
  background: radial-gradient(
    90.56% 231.82% at 9.44% 12.39%,
    #ffdf00 0%,
    #efd100 100%
  );
  width: 1rem;
  height: 1rem;
}

.profile-image {
  object-fit: cover;
}

.z-100 {
  z-index: 100;
}

.white {
  color: white;
}

.alert-info {
  border: none;
  border-left: 4px solid #00b7ff;
  color: #005c71;
  background: #80c9ff42;
  border-radius: 0 0.4rem 0.4rem 0;
}

.alert-danger {
  border: none;
  border-left: 4px solid #d00000;
  background: #ff80803b;
  border-radius: 0 0.4rem 0.4rem 0;
  color: #ea4200;
}

.alert-success {
  border: none;
  border-left: 4px solid #28a745;
  color: #28a745;
  background: #28a7453b;
  border-radius: 0 0.4rem 0.4rem 0;
}

.alert-warning {
  border: none;
  border-left: 4px solid #ff9d00;
  background: #ffb05a3b;
  border-radius: 0 0.4rem 0.4rem 0;
  color: #a22e00;
}

.status-bar {
  background-color: #dedede;
  border: 1px solid #d0d0d0;
  border-right: none;
  border-left: none;
}

.prop-group {
  border-left: 1px solid #cacaca;
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

// sizes
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}

.w-45 {
  width: 45%;
}

.w-75 {
  width: 75%;
}

.h-36 {
  height: 36px;
}

// font weight
.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

// dropdown styling
.dropdown-option {
  cursor: pointer;
  padding: 5px;
}

.dropdown-option.disabled {
  color: var(--bs-grey);

  img {
    filter: grayscale(1);
  }
}

:root {
  --backgroundColour: white;
}

.light-mode {
  --backgroundColour: white;
  --backgroundPageColour: white;
  --shadowColour: #00000025;
  --shadowColourDarker: #00000050;
  --fontColour: black;
  --fontMutedColour: #777777;
  --listHoverColour: #00000025;
  --linkColour: #2b57f4;
}

// Dark mode
.dark-mode {
  --backgroundElementColour: #141b25;
  --backgroundColour: #1a232f;
  --backgroundPageColour: #242d39;
  --shadowColour: #00000075;
  --shadowColourDarker: #000000aa;
  --fontColour: white;
  --fontMutedColour: #cccccc;
  --listHoverColour: #00000025;
  --linkColour: #88a2ff;

  .alert {
    color: var(--fontColour);
  }

  .card {
    border: none;
  }

  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    font-family: 'SofiaPro', sans-serif;
    color: var(--fontColour);
  }

  .text-muted {
    color: var(--fontMutedColour) !important;
  }

  .table {
    background-color: var(--backgroundElementColour);
    color: var(--fontColour);

    th {
      background-color: var(--bs-warning) !important;
      color: black;
    }
  }

  .nav-tabs {
    background-color: var(--backgroundPageColour);
    border-bottom: var(--backgroundPageColour);
  }

  .list-group-item {
    color: var(--fontColour);
    background-color: var(--backgroundPageColour);
  }

  .table th,
  .table td,
  .border,
  .border-left,
  .border-right,
  .border-bottom,
  .border-top {
    border-color: #777777 !important;
  }

  .table td {
    color: var(--fontColour);
    background-color: var(--backgroundPageColour);
  }

  input,
  .form-control,
  .ng-select-container {
    background-color: var(--backgroundElementColour);
    color: var(--fontColour);
  }

  .ng-option {
    // background-color: var(--backgroundPageColour) !important;
    background-color: unset !important;
    color: var(--fontColour) !important;
  }

  .ng-dropdown-panel {
    background-color: var(--backgroundPageColour) !important;
  }

  .ng-option:hover {
    background-color: var(--listHoverColour) !important;
  }

  // .ng-option,
  .ng-select-container {
    background-color: var(--backgroundElementColour) !important;
  }

  .ng-dropdown-panel,
  .ng-select-container {
    border: none;
  }
}

.tooltip {
  pointer-events: none;
}

.no-wrap {
  white-space: nowrap;
}

.grecaptcha-badge {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .h1,
  h1 {
    font-size: 2rem;
  }

  .h2,
  h2 {
    font-size: 1.4rem;
  }

  .h3,
  h3 {
    font-size: 1.2rem;
  }

  .h4,
  h4 {
    font-size: 1rem;
  }

  .h5,
  h5 {
    font-size: 0.8rem;
  }

  .h6,
  h6 {
    font-size: 0.8rem;
  }

  p {
    font-size: 1.2rem;
  }
}
