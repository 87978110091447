.item-creation {
  background-color: #1e2733;
  width: 100%;
  min-height: 100vh;

  .dropdown-image-w3 {
    width: 2.5rem;
    height: auto;
  }

  .hero-header {
    font-size: 4.5em;
  }

  .hero-subtitle {
    font-size: 2em;
    margin-top: 2rem;
  }

  .hero-description {
    font-size: 1.5em;
    margin-top: 1rem;
  }

  .card-body {
    .h3 {
      margin-top: 2rem;
    }
    .h4 {
      margin-top: 1.75rem;
    }

    .btn-primary,
    .btn-secondary,
    .btn-info,
    .btn-link {
      width: 100%;
      margin-top: 1.5rem;
    }
  }

  .card-shadow {
    box-shadow: 0px 9px 3rem rgb(0 0 0) !important;
  }

  .card-section-break {
    height: 1rem;
    width: 100%;
    border: none;
    background: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .link {
    color: #6e6e6e;
  }

  .link-active {
    color: var(--bs-yellow);
    text-underline-offset: 0.1rem;
    text-decoration-color: var(--bs-yellow);
    text-decoration: underline;
    cursor: pointer;
  }

  .background-image {
    position: fixed;
    background: url("../assets/images/capsule-create-backdrop-2.jpg");
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .color-opacity-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 1;
    background-color: rgba(14, 18, 23, 0.8);
  }

  .no-bottom-border {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .no-top-border {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .relative {
    position: relative;
  }

  .active {
    background: var(--bs-yellow);
    border: 1px solid var(--bs-yellow) !important;
  }

  .active:hover {
    background: var(--bs-yellow);
    border: 1px solid var(--bs-yellow) !important;
  }

  @media screen and (max-width: 992px) {
    .hero-header {
      font-size: 3.5em;
      line-height: 1;
      margin-bottom: 14px;
    }

    .hero-subtitle {
      margin-top: 0;
      font-size: unset;
    }

    .hero-description {
      font-size: 1rem;
    }

    .background-image {
      background-position: center;
    }

    .mobile-card-padding {
      padding: 2rem;
    }

    .hr-my-sm-32 {
      margin: 32px 0 !important;
    }

    .sm-no-padding-col {
      padding: 0;

      .card {
        border: none;

        .card-body {
          background-color: transparent;
        }
      }
    }

    .px-sm-32 {
      padding: 0 32px 32px;
    }
  }
}
