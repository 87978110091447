// layout
.layout-header {
  min-height: 50px;
  display: flex;
  align-items: center;
}

// breadcrumbs
.breadcrumbs-container {
  .border-right {
    transform: rotate(20deg);
    border-right-width: 2px !important;
    height: 20px;
    border-right: 1px solid #dee2e6 !important;
  }

  .single {
    display: flex;
  }

  .multiple {
    display: none;
  }
}

// card
.dash-card,
.card-body {
  box-shadow: var(--shadowColour) 0 0 5px 0;
  background-color: var(--backgroundColour);
  color: var(--fontColour);
  height: 100%;
  flex-grow: 1;

  padding: 1rem;

  a {
    color: var(--linkColour);
  }
}

@media only screen and (min-width: 768px) {
  .dash-card,
  .card-body {
    padding: 2rem;
  }
}

@media only screen and (min-width: 990px) {
  .dash-card,
  .card-body {
    padding: 3rem;
  }

  .breadcrumbs-container {
    .single {
      display: none;
    }

    .multiple {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

// tab menu
.tab-menu {
  -webkit-user-drag: none;

  a {
    color: var(--fontColour);
    text-decoration: none;
    padding-bottom: 0.25rem;
    margin-bottom: 0.75rem;
    min-width: 100px;
    text-align: center;
  }

  a:hover {
    border-bottom: 2px solid #e3e3e3;
  }

  a.active {
    font-weight: bold;
    border-bottom: 2px solid var(--bs-yellow);
  }
}

@media only screen and (min-width: 0px) {
  .tab-menu {
    display: none;
  }

  .tab-dropdown {
    display: initial;
  }
}

// @media only screen and (min-width: 960px) {
@media only screen and (min-width: 768px) {
  .tab-dropdown {
    display: none;
  }

  .tab-menu {
    display: flex;
    flex-wrap: wrap;
  }
}

//roles
.text-owner {
  color: #2b7bf4 !important;
}

.text-editor {
  color: #f42b2b !important;
}

.text-viewer {
  color: #2bf494 !important;
}

.bg-owner {
  background-color: #2b7bf4;
}

.bg-editor {
  background-color: #f42b2b;
}

.bg-viewer {
  background-color: #2bf494;
}

.bg-user {
  background-color: #8a5612;
}

// TODO: Brenton - temp
.text-user {
  color: #f42b2b !important;
}

.bg-user {
  background-color: #f42b2b !important;
}

.no-items-text {
  color: var(--fontColour) !important;
}
