@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/Icons/icomoon.eot');
  src:  url('../assets/fonts/Icons/icomoon.eot#iefix') format('embedded-opentype'),
    // url('../assets/fonts/Icons/icomoon.ttf') format('truetype'),
    url('../assets/fonts/Icons/icomoon.woff') format('woff'),
    url('../assets/fonts/Icons/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mongodb:before {
  content: "\e901";
}
.icon-mysql:before {
  content: "\e902";
}
.icon-redis:before {
  content: "\e903";
}
.icon-github:before {
  content: "\e904";
}
.icon-link:before {
  content: "\e905";
}
.icon-slack:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-speedometer:before {
  content: "\e908";
}
.icon-team:before {
  content: "\e909";
}
.icon-trash:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
}
.icon-logout:before {
  content: "\e90c";
}
.icon-minus:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-right-chevron:before {
  content: "\e90f";
}
.icon-house:before {
  content: "\e910";
}
.icon-list:before {
  content: "\e911";
}
.icon-kebab-menu-v:before {
  content: "\e912";
}
.icon-layers:before {
  content: "\e913";
}
.icon-layout:before {
  content: "\e914";
}
.icon-left-chevron:before {
  content: "\e915";
}
.icon-frontend:before {
  content: "\e916";
}
.icon-gear:before {
  content: "\e917";
}
.icon-git-branch:before {
  content: "\e918";
}
.icon-git-repo:before {
  content: "\e919";
}
.icon-grid-even:before {
  content: "\e91a";
}
.icon-grid-uneven:before {
  content: "\e91b";
}
.icon-backend:before {
  content: "\e91c";
}
.icon-bell:before {
  content: "\e91d";
}
.icon-check:before {
  content: "\e91e";
}
.icon-cloud:before {
  content: "\e91f";
}
.icon-cross:before {
  content: "\e920";
}
.icon-data:before {
  content: "\e921";
}
.icon-calendar:before {
  content: "\e922";
}
.icon-down-chevron:before {
  content: "\e923";
}
.icon-up-chevron:before {
  content: "\e924";
}
.icon-build:before {
  content: "\e925";
}
.icon-capsule:before {
  content: "\e926";
}
.icon-domain:before {
  content: "\e927";
}
.icon-external-link:before {
  content: "\e928";
}
.icon-space:before {
  content: "\e929";
}
.icon-usage:before {
  content: "\e92a";
}
.icon-docker:before {
  content: "\e92b";
}
.icon-send-mail:before {
  content: "\e92c";
}
.icon-edit:before {
  content: "\e92d";
}
.icon-copy:before {
  content: "\e92e";
}
.icon-postgresql:before {
  content: "\e92f";
}
.icon-angular:before {
  content: "\e930";
  color: #dd0031;
}
.icon-go:before {
  content: "\e931";
  color: #00add8;
}
.icon-html5:before {
  content: "\e932";
  color: #e34f26;
}
.icon-java:before {
  content: "\e933";
  color: #007396;
}
.icon-laravel:before {
  content: "\e934";
  color: #ff2d20;
}
.icon-nodejs:before {
  content: "\e935";
  color: #393;
}
.icon-php:before {
  content: "\e936";
  color: #777bb4;
}
.icon-python:before {
  content: "\e937";
  color: #3776ab;
}
.icon-react:before {
  content: "\e938";
  color: #61dafb;
}
.icon-vue:before {
  content: "\e939";
  color: #4fc08d;
}
.icon-wordpress:before {
  content: "\e900";
}
